import React, { lazy, Suspense } from "react"
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import Spinner from './@core/components/spinner/Fallback-spinner'
import "./App.css"
const AppWrapper = lazy(() => import('./AppWrapper'))

ReactDOM.render(
  <Suspense fallback={
    <div className="main-loader-style">
      <Spinner />
    </div>
  }>
    <AppWrapper />
  </Suspense>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
